<template>
  <q-spacer bottom="sm">
    <q-grid
      :column="products.lengh > 3 ? 4 : 3"
      style="
        grid-template-columns: repeat(4, 25%);
        overflow: scroll;
        background: white;
      "
    >
      <q-card
        v-for="(product, index) in products"
        :key="index"
        :title="product.name"
        elevation="flat"
        style="border: 0"
      >
        <q-padder vertical="sm" class="product">
          <q-text
            :id="`btn-${product.type.toLowerCase()}`"
            tag="div"
            @click.native="purchaseDirect(product.link, product.type)"
          >
            <q-text tag="div" class="product__wrapper">
              <div v-if="product.type === 'vehicle'">
                <MyVehicle />
              </div>
              <div v-if="product.type === 'property'">
                <Property />
              </div>
              <div v-if="product.type === 'personal-accident'">
                <PersonalAccidentIcon />
              </div>
              <div v-if="product.type === 'Smartphone'">
                <SmartphoneIcon v-if="product.active" />
                <SmartphoneIconDis v-else />
              </div>
              <div v-if="product.type === 'Car'">
                <CarIcon v-if="product.active" />
                <CarIconDis v-else />
              </div>
              <div v-if="product.type === 'Motorcycle'">
                <MotorIcon v-if="product.active" />
                <MotorIconDis v-else />
              </div>
              <div v-if="product.type === 'Health'">
                <div v-if="countryCode === 'MY'">
                  <div v-if="!product.active">
                    <ComingSoonLabel class="category-label"></ComingSoonLabel>
                    <HealthIconMalayDis />
                  </div>
                  <div v-else>
                    <NewLabel class="category-label"></NewLabel>
                    <HealthIconMalay />
                  </div>
                </div>
                <div v-else>
                  <HealthIcon v-if="product.active" />
                  <HealthIconDis v-else />
                </div>
              </div>
              <div v-if="product.type === 'HCP'">
                <HcpIcon v-if="product.active" />
                <HcpIconDis v-else />
              </div>
              <div v-if="product.type === 'Life'">
                <LifeIcon v-if="product.active" />
                <LifeIconDis v-else />
              </div>
              <div v-if="product.type === 'Tropical Disease'">
                <TropicalIcon v-if="product.active" />
                <TropicalIconDis v-else />
              </div>
              <div v-if="product.type === 'Travel'">
                <TravelIcon v-if="product.active" />
                <TravelIconDis v-else />
              </div>
              <q-text
                v-if="product.badge && isBadgeShow"
                tag="span"
                ink="white"
                bold
              >
                {{ product.badge }}
              </q-text>
            </q-text>
            <q-padder top="xs">
              <q-padder top="xs">
                <q-text
                  tag="h2"
                  ink="label"
                  :variant="countryCode === 'MY' ? 'ui-tiny' : 'ui-small'"
                  bold
                  center
                  style="margin-bottom: 0"
                  >{{ product.name }}</q-text
                >
              </q-padder>
            </q-padder>
          </q-text>
        </q-padder>
      </q-card>
    </q-grid>
  </q-spacer>
</template>

<script>
import {
  Padder as QPadder,
  Card as QCard,
  Text as QText,
  Spacer as QSpacer,
  Grid as QGrid,
} from '@qoala/ui';
export default {
  components: {
    QPadder,
    QCard,
    QText,
    QSpacer,
    QGrid,
    SmartphoneIcon: () =>
      import('~/assets/images/homepage/icon-smartphone.svg'),
    Property: () => import('~/assets/images/homepage/icon-house.svg'),
    CarIcon: () => import('~/assets/images/homepage/icon-car.svg'),
    MotorIcon: () => import('~/assets/images/homepage/icon-motorcycle.svg'),
    TropicalIcon: () =>
      import('~/assets/images/homepage/icon-tropical-disease.svg'),
    TropicalIconDis: () =>
      import('~/assets/images/homepage/icon-tropical-disease-dis.svg'),
    HealthIcon: () => import('~/assets/images/homepage/icon-health.svg'),
    HealthIconMalayDis: () =>
      import('~/assets/images/homepage/icon-health-malay-dis.svg'),
    HealthIconMalay: () =>
      import('~/assets/images/homepage/icon-health-malay.svg'),
    HcpIcon: () => import('~/assets/images/homepage/icon-hcp.svg'),
    LifeIcon: () => import('~/assets/images/homepage/icon-life-insurance.svg'),
    NewLabel: () => import('~/assets/images/homepage/new-label.svg'),
    ComingSoonLabel: () =>
      import('~/assets/images/homepage/coming-soon-label.svg'),
    SmartphoneIconDis: () => import('~/assets/icons/products/Smartphone-3.svg'),
    CarIconDis: () => import('~/assets/icons/products/Auto-3.svg'),
    MotorIconDis: () => import('~/assets/icons/products/Motorcycle-3.svg'),
    HealthIconDis: () => import('~/assets/icons/products/Health-3.svg'),
    HcpIconDis: () => import('~/assets/icons/products/Hcp-3.svg'),
    LifeIconDis: () => import('~/assets/icons/products/Life-3.svg'),
    TravelIcon: () => import('~/assets/images/homepage/icon-travel.svg'),
    TravelIconDis: () => import('~/assets/icons/products/Travel-3.svg'),
    MyVehicle: () => import('~/assets/icons/products/MyVehicle.svg'),
    PersonalAccidentIcon: () =>
      import('~/assets/images/homepage/icon-personal-accident.svg'),
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
    countryCode: {
      type: String,
      default: () => 'ID',
    },
  },
  data() {
    return {
      isBadgeShow: false,
    };
  },
  computed: {
    isDisabled() {
      return this.countryCode !== 'ID';
    },
    products() {
      if (this.countryCode === 'MY') {
        return [
          {
            name: this.content.QoalaOnlineHomepage.carLabel,
            type: 'Car',
            link: 'landing-car-my',
            active: true,
          },
          {
            name: this.content.QoalaOnlineHomepage.motorLabel,
            type: 'Motorcycle',
            link: 'landing-motorcycle-my',
            active: true,
          },
          {
            name: this.content.QoalaOnlineHomepage.healthLabel,
            type: 'Health',
            link: 'landing-health-my',
            active: true,
          },
          {
            name: this.content.QoalaOnlineHomepage.travelLabel,
            type: 'Travel',
            link: `${process.env.TRAVEL_INSURANCE_PAPERWORK_URL}`,
            active: true,
          },
          {
            name: this.content.QoalaOnlineHomepage.hartaTanahLabel,
            type: 'property',
            link: 'landing-property',
            active: true,
          },
          {
            name: this.content.QoalaOnlineHomepage.personalAccident,
            type: 'personal-accident',
            link: 'purchase-personal-accident-product-detail',
            active: true,
          },
        ];
      } else if (this.countryCode === 'VN') {
        return [
          {
            name: this.content.QoalaOnlineHomepage.smartphoneLabel,
            type: 'Smartphone',
            link: 'policy-add',
            active: true,
          },
        ];
      } else  {
        return [
          // {
          //   name: this.content.QoalaOnlineHomepage.smartphoneLabel,
          //   type: 'Smartphone',
          //   // temporarily disabled
          //   link: 'index',
          //   active: false,
          // },
          {
            name: this.content.QoalaOnlineHomepage.carLabel,
            type: 'Car',
            link: this.countryCode === 'ID' ? 'purchase-car' : 'index',
            active: this.countryCode === 'ID' ? true : false,
          },
          {
            name: this.content.QoalaOnlineHomepage.motorLabel,
            type: 'Motorcycle',
            link: this.countryCode === 'ID' ? 'landing-motor' : 'index',
            active: this.countryCode === 'ID' ? true : false,
          },
          {
            name: this.content.QoalaOnlineHomepage.healthLabel,
            type: 'Health',
            link: this.countryCode === 'ID' ? 'landing-health' : 'index',
            active: this.countryCode === 'ID' ? true : false,
          },
          {
            name: this.content.QoalaOnlineHomepage.hcpLabel,
            type: 'HCP',
            link: this.countryCode === 'ID' ? 'landing-hcp' : 'index',
            active: this.countryCode === 'ID' ? true : false,
          },
          {
            name: this.content.QoalaOnlineHomepage.lifeLabel,
            type: 'Life',
            link: this.countryCode === 'ID' ? 'landing-life' : 'index',
            active: this.countryCode === 'ID' ? true : false,
          },
          {
            name: this.content.QoalaOnlineHomepage.tropicalDiseaseLabel,
            type: 'Tropical Disease',
            link:
              this.countryCode === 'ID' ? 'purchase-tropical-disease' : 'index',
            active: this.countryCode === 'ID' ? true : false,
          },
          // {
          //   name: this.content.QoalaOnlineHomepage.travelLabel,
          //   type: 'Travel',
          //   link: 'index',
          //   active: false,
          // },
        ];
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.isBadgeShow = true;
    }, 500);
  },
  methods: {
    purchaseDirect(url, type) {
      if (type !== 'disable') {
        if (this.countryCode === 'ID') {
          this.$analytics.pushEvent({
            event: `Homepage ${type} Icon Click`,
            eventCategory: `${type}-Funel`,
            eventAction: `Homepage ${type} click `,
            eventLabel: `submit`,
          });
        }
        let eventPropertyName = 'Product Category ';
        if (type === 'vehicle') {
          eventPropertyName += 'MV';
        }
        eventPropertyName += this.$helper.capitalize(type);
        this.$analytics.pushEventClevertap(eventPropertyName, {
          'Product Category': type,
        });
      }
      const checkDomain = (url) => {
        if (url.indexOf('//') === 0) {
          url = location.protocol + url;
        }
        return url
          .toLowerCase()
          .replace(/([a-z])?:\/\//, '$1')
          .split('/')[0];
      };
      const isExternalUrl = (url) => {
        return (
          (url.indexOf(':') > -1 || url.indexOf('//') > -1) &&
          checkDomain(location.href) !== checkDomain(url)
        );
      };
      if (isExternalUrl(url)) {
        window.location.href = url;
      } else {
        this.$router.push(this.localePath(url));
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.product {
  text-align: center;
  width: fit-content;
  margin: auto;
  cursor: pointer;
  &__wrapper {
    position: relative;
    width: fit-content;
    margin: auto;
    img {
      height: 32px;
    }
    svg {
      height: 32px;
      width: 32px;
    }
    span {
      font-size: 8px;
      border-radius: 8px;
      background: #ff6400;
      padding: 1px 6px;
      position: absolute;
      top: -3px;
      right: -10px;
    }
  }
}

.category-label {
  position: absolute;
  left: 0px;
  top: -21px;
  z-index: 10;
}
</style>
