<template>
  <q-spacer top="xxs" class="banner-hooper">
    <hooper :auto-play="true" :play-speed="5000">
      <slide v-for="(item, key) in carouselList" :key="key">
        <tool-image-kit
          :src="item.value"
          :alt="getCarouselItemName(item, key)"
          :title="getCarouselItemName(item, key)"
          :loading="key === 0 ? '' : 'lazy'"
          :lqip="{ active: true, quality: 30, blur: 5 }"
          :transformation="[{ width: 512 }]"
          height="162"
          width="400"
          @click.native="detailBanner(item.item)"
        />
      </slide>
      <hooper-pagination slot="hooper-addons" />
    </hooper>
    <q-text
      id="btn-seeAllPromo"
      :title="content.QoalaOnlineHomepage.seePromoLabel"
      tag="span"
      variant="ui-tiny"
      ink="primary"
      bold
      class="banner-hooper__btn"
      @click.native="goToPromoPage"
    >
      {{ content.QoalaOnlineHomepage.seePromoLabel }}
    </q-text>
  </q-spacer>
</template>

<script>
import { Hooper, Slide, Pagination as HooperPagination } from 'hooper';
import { Text as QText, Spacer as QSpacer } from '@qoala/ui';
import ToolImageKit from './ToolImageKit.vue';

export default {
  components: {
    Hooper,
    Slide,
    HooperPagination,
    QText,
    QSpacer,
    ToolImageKit,
  },
  props: {
    carouselList: {
      type: Array,
      default: () => [],
    },
    content: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    getCarouselItemName(item, key) {
      return item.item?.name ?? 'Carousel Item ' + key;
    },
    detailBanner(banner) {
      let pageUrl = window.location.href || '';
      pageUrl = pageUrl.replaceAll('&', '%26');
      this.$analytics.pushEventClevertap('Banner Detail Page', {
        'Banner ID': String(banner.query?.id),
        'Banner Code': banner.name,
        'Banner Path':
          pageUrl +
          `/promo/detail?promoCode=${banner.name}&id=${banner.query?.id}`,
      });
      if (banner.static) {
        this.$analytics.pushEvent({
          event: 'Homepage',
          eventCategory: 'Homepage',
          eventAction: 'Banner',
          eventLabel: `Homepage-Click-BN-${banner.name}`,
        });
        if (banner.url.startsWith('http')) {
          window.location.href = banner.url;
          return;
        }
        if (banner.query) {
          return this.$router.push({
            path: this.localePath(banner.url),
            query: banner.query,
          });
        } else {
          return this.$router.push(this.localePath(banner.url));
        }
      }
    },
    goToPromoPage() {
      this.$analytics.pushEvent({
        event: 'Homepage',
        eventCategory: 'Homepage',
        eventAction: 'Promotion',
        eventLabel: 'Homepage-Click-All-Promo',
      });
      this.$router.push(this.localePath('promo'));
    },
  },
};
</script>

<style lang="scss">
@import '~/assets/css/mixins.scss';
.hooper,
.hooper * {
  box-sizing: border-box;
}
.hooper {
  height: auto;
  .hooper-list {
    overflow: hidden;
    width: 100%;
    height: auto;
    position: relative;
    .hooper-track {
      display: flex;
      height: auto;
      width: 100%;
      padding: 0;
      margin: 0;
      .hooper-slide {
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-self: center;
        height: fit-content;
        margin: 0;
        padding: 0;
        list-style: none;
        img {
          width: 100%;
        }
      }
    }
    .hooper-pagination {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 5px 0;
      .hooper-indicators {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        .hooper-indicator {
          margin: 0 4px;
          padding: 0;
          width: 6px;
          height: 6px;
          border-radius: 10px;
          border: none;
          background-color: #e6e6e6;
          cursor: pointer;
          &.is-active {
            width: 24px;
          }
          &.is-active,
          &:hover {
            background-color: #ff6400;
          }
        }
      }
    }
  }
}
.hooper-sr-only {
  display: none;
}
.banner-hooper {
  position: relative;
  .hooper {
    &-list {
      padding-bottom: 28px;
      .hooper-track {
        .hooper-slide {
          min-height: 106px;
          width: 100% !important;
          img {
            border-radius: 8px;
            cursor: pointer;
          }
        }
      }
    }
  }
  &__btn {
    position: absolute;
    right: 0;
    bottom: 6px;
    cursor: pointer;
  }
}
</style>
