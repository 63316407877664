<template>
  <div class="progress">
    <div :style="{ width: width }" class="progress--bar" />
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    maxValue: {
      type: Number,
      default: 100
    },
    minValue: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      width: ''
    };
  },
  created() {
    const a = (this.value * 100) / this.maxValue;
    this.width = `${a}%`;
  }
};
</script>

<style lang="scss" scoped>
.progress {
  display: -ms-flexbox;
  display: flex;
  height: 12px;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e6f7ff;
  border-radius: 6px;
  &--bar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    background-color: #fdc000;
    transition: width 0.6s ease;
    border-radius: 6px;
    width: 0;
  }
}
</style>
