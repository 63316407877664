<template>
  <div class="tool-image-kit-section">
    <ik-image v-bind="$attrs" />
  </div>
</template>

<script>
export default {
  name: 'ToolImageKit',
};
</script>

<style lang="scss" scoped>
@import '~/assets/css/mixins.scss';

.tool-image-kit-section {
  width: 100%;
  min-height: 140px;
  img {
    @include bannerImage;
  }
}
</style>