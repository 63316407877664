interface WebpagePayload {
  // Country of the Qoala App currently accessed
  country: string;
  // Meta Title of the Webpage
  name: string;
  // Meta Description of the Webpage
  description: string;
}

interface LogoImagePayload {
  // Country of the Qoala App currently accessed
  country: string;
}

interface OrganizationPayload {
  // Country of the Qoala App currently accessed
  country: string;
}

interface SiteNavigationElementList {
  // Product Name
  name: string;
  // Product Description
  description: string;
  // Product URI
  url: string;
}

interface SiteNavigationElementPayload {
  // Site Navigation Name
  name: string;
  // Product List
  productList: SiteNavigationElementList[];
}

interface AddressPayload {
  // Company address locality
  addressLocality: string;
  // Company address region
  addressRegion: string;
  // Company postal code
  postalCode: string;
  // Company street address
  streetAddress: string;
}

interface InsuranceAgencyPayload {
  // Country of the Qoala App currently accessed
  country: string;
  // Company name
  name: string;
  // Company description
  description: string;
  // Company telephone
  telephone: string;
}

interface BreadcrumbList {
  // Product / Insurance Name
  name: string;
  // URI of the Product
  url: string;
}

interface BreadcrumbPayload {
  // Country of the Qoala App currently accessed
  country: string;
  // Breadcrumb list
  breadcrumbList: BreadcrumbList[];
}

interface FinancialProductPayload {
  // Country of the Qoala App currently accessed
  country: string;
  // Product title
  name: string;
  // Product description
  description: string;
  // Product category name
  category: string;
};

const SCHEMA_URL = 'https://schema.org';
const QOALA_BASE_URL = 'https://www.qoala.app';

export function webpageSchema(payload: WebpagePayload) {
  const { country, name, description } = payload;

  return {
    type: 'application/ld+json',
    json: {
      '@context': SCHEMA_URL,
      '@type': 'WebSite',
      '@id': `${QOALA_BASE_URL}/${country}/#website`,
      url: `${QOALA_BASE_URL}/${country}`,
      name,
      description,
    },
  };
}

export function logoImageSchema(payload: LogoImagePayload) {
  const { country } = payload;

  return {
    logo: {
      '@type': 'ImageObject',
      '@id': `${QOALA_BASE_URL}/${country}/#logo`,
      inLanguage: country,
      url: 'https://assets.qoala.app/images/icons/qoala.png',
      width: 313,
      height: 260,
      caption: 'PT. Archor Technology Digital',
    },
  };
}

export function organizationSchema(payload: OrganizationPayload) {
  const { country } = payload;

  return {
    type: 'application/ld+json',
    json: {
      '@context': SCHEMA_URL,
      '@type': 'Organization',
      '@id': `${QOALA_BASE_URL}/${country}/#organization`,
      url: `${QOALA_BASE_URL}/${country}`,
      name: 'PT. Archor Technology Digital',
      image: {
        '@id': `${QOALA_BASE_URL}/${country}/#logo`,
      },
      ...logoImageSchema({ country }),
    },
  };
}

export function siteNavigationElementSchema(payload: SiteNavigationElementPayload) {
  const { name, productList } = payload;
  return {
    type: 'application/ld+json',
    json: {
      '@context': SCHEMA_URL,
      '@type': ['SiteNavigationElement', 'ItemList'],
      name,
      itemListElement: productList.map((product, index) => ({
        '@type': 'WebPage',
        position: index + 1,
        name: product.name,
        description: product.description,
        url: `${QOALA_BASE_URL}${product.url}`,
      })),
    },
  };
}

function _addressSchema(payload: AddressPayload) {
  return {
    address: {
      '@type': 'PostalAddress',
      ...payload,
    },
  };
}

export function insuranceAgencySchema(
  payload: InsuranceAgencyPayload,
  addressPayload: AddressPayload,
) {
  const { country, name, description, telephone } = payload;
  return {
    type: 'application/ld+json',
    json: {
      '@context': SCHEMA_URL,
      '@type': 'InsuranceAgency',
      '@id': `${QOALA_BASE_URL}/${country}/#insuranceAgency`,
      url: `${QOALA_BASE_URL}/${country}`,
      name,
      description,
      telephone,
      ..._addressSchema(addressPayload),
    },
  };
}

export function breadcrumbListSchema(payload: BreadcrumbPayload) {
  const { country, breadcrumbList } = payload;
  return {
    type: 'application/ld+json',
    json: {
      '@context': SCHEMA_URL,
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbList.map((breadcrumb, index) => ({
        '@type': 'ListItem',
        'position': index + 1,
        'item': {
          '@id': `${QOALA_BASE_URL}/${country}#${encodeURIComponent(breadcrumb.name)}`,
          '@type': 'WebPage',
          name: breadcrumb.name,
          url: `${QOALA_BASE_URL}${breadcrumb.url}`,
        }
      })),
    },
  };
}

export function financialProductSchema(payload: FinancialProductPayload) {
  const { country, name, description, category } = payload;
  return {
    type: 'application/ld+json',
    json: {
      '@context': SCHEMA_URL,
      '@type': 'FinancialProduct',
      name,
      description,
      category,
      ...logoImageSchema({ country }),
    },
  };
}
