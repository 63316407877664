<script>
import PageBuilder from '~/core/PageBuilder';
import HomePage from '~/src/home';

export default new PageBuilder({
  layout: 'default',
  serverTemplate: HomePage,
  components: {
    HomePage,
  },
  queries: {
    MetaTitle: [],
    MetaDescription: [],
    QoalaOnlineHomepage: [],
    QoalaOnlineGeneral: [],
    QoalaOnlineAccount: [],
    QoalaOnlineAboutUs: [],
    QoalaOnlineCarTooltip: [],
    QoalaOnlineChangePassword: [],
  },
  method: 'get',
  async asyncFunc(ctx) {
    let bannerList = [];
    try {
      const countryCode = ctx.store.getters['core/getCountryCode'];

      let promo = await ctx.store.dispatch('promo/getListPromotion', {
        page: 1,
        country: countryCode,
        isBanner: true,
      });
      promo.voucher_list.forEach((promo) => {
        const details = promo.voucher_detail;
        const enDetail = details.find((detail) => detail.lang === 'EN');
        const locale =
          details.find((detail) => detail.lang === 'ID') ||
          details.find((detail) => detail.lang === 'MY');
        if (promo.voucher_detail[1]) {
          bannerList.push({
            en: enDetail.voucher_asset.public_image_url,
            locale: locale.voucher_asset.public_image_url,
            url: locale.redirect_url || 'promo-detail',
            query: { promoCode: promo.code, id: promo.id },
            name: promo.code,
            rank: promo.rank,
          });
        }
      });

      const order = {
        QOALAMOBIL25: 1,
        QOALASEHAT10: 2,
        QOALAPHONE40: 3,
        CCINSTALLMENT: 4,
        QOALASANTUNAN20: 5,
        QOALAJIWA20: 6,
        QOALAMOTOR20: 7,
        REFERRALCAMPAIGN: 8,
      };
      // filtering the bannerlist having the rank and sorting in accending order
      const filteredRankBannerList = bannerList
        .filter((item) => item.rank !== null)
        .sort((a, b) => a.rank - b.rank);
      // filtering the bannerlist having the rank null
      const filteredNonRankBannerList = bannerList.filter(
        (item) => item.rank === null
      );
      bannerList = [
        ...filteredRankBannerList,
        ...(filteredNonRankBannerList ? filteredNonRankBannerList : []),
      ];
    } catch (e) {
      bannerList = [];
    }

    return { bannerList };
  },
}).render();
</script>
