<template>
  <general-template :content="content" tracking-event="Homepage">
    <q-padder style="position: relative">
      <q-padder style="background: white">
        <q-padder horizontal="xs">
          <add-to-home-screen />
          <tool-slider
            :carousel-list="carousel"
            :content="content"
            :is-loading="isBannerLoading"
          />
        </q-padder>
      </q-padder>
      <product-section :content="content" :country-code="countryCode" />
      <q-padder
        v-if="isLoggedIn && progressValue < 100"
        horizontal="xs"
        bottom="sm"
      >
        <q-card :style="boxShadow">
          <q-padder top="sm" horizontal="sm">
            <tool-progress-bar :value="progressValue" :max-value="100" />
            <q-padder top="xxs" left="xxs" bottom="xs">
              <div
                @click="$router.push(localePath('account'))"
                @keypress="$router.push(localePath('account'))"
              >
                <q-text ink="muted" variant="ui-tiny">{{
                  statusVerified
                }}</q-text>
              </div>
            </q-padder>
          </q-padder>
        </q-card>
      </q-padder>
    </q-padder>
    <q-spacer v-if="countryCode === 'MY'" bottom="sm">
      <vehicle-protection-section
        :content="{
          ...content.QoalaOnlineGeneral,
          ...content.QoalaOnlineHomepage,
          ...content.QoalaOnlineError,
          ...content.QoalaOnlineCarTooltip,
        }"
        :is-home="true"
        product-type="car-my"
        @search-product="handleSearchProduct"
      />
    </q-spacer>
    <q-padder
      v-if="!isLoggedIn && countryCode === 'ID'"
      horizontal="sm"
      top="xxs"
      bottom="md"
    >
    </q-padder>
    <!-- USP Section -->
    <usp-section :content="content" />
    <!-- Edu Page Section -->
    <soft-sell-section v-if="isShowContent" :content="content" />
    <!-- Banner Partnership Promo Section -->
    <q-spacer
      v-if="isShowContent"
      vertical="xxs"
      class="eb-section"
      :title="content.QoalaOnlineHomepage.partnerPromoTitleLabel"
      @click.native="goToPartnerPromoPage"
    >
      <q-column justify="center" class="eb-wrapper">
        <q-text variant="ui-large" ink="label">{{
          content.QoalaOnlineHomepage.partnerPromoTitleLabel
        }}</q-text>
        <q-spacer top="xs">
          <q-button
            size="sm"
            type="primary"
            :title="content.QoalaOnlineHomepage.partnerPromoButtonLabel"
          >
            {{ content.QoalaOnlineHomepage.partnerPromoButtonLabel }}
          </q-button>
        </q-spacer>
      </q-column>
      <img
        src="https://img.qoala.app/qapp-assets/images/homepage/partner-banner.jpg?tr=h-101,w-359"
        alt="Banner Partnership Promo"
        style="width: 100%"
        width="359"
        height="101"
        title="Partner Banner Promo"
      />
    </q-spacer>
    <!-- Article Section -->
    <article-section :content="content" />
    <!-- FAQ Section (Currently Malay only) -->
    <faq-section v-if="countryCode === 'MY'" :content="content" />
    <!-- Partner & OJK Section -->
    <partner-section
      :content="content"
      :country-code="countryCode"
      :insurance-partner-title="
        content.QoalaOnlineHomepage.insurancePartnerLabel
      "
      :media-partner-label="content.QoalaOnlineHomepage.mediaPartnerLabel"
      :payment-option-label="content.QoalaOnlineHomepage.paymentOptionLabel"
      :many-more-label="content.QoalaOnlineGeneral.manyMoreLabel"
      with-media-partners
    />
    <!-- General Contact Section -->
    <q-padder vertical="xs" horizontal="xs">
      <q-card
        elevation="interactive"
        style="border: 0; box-shadow: 0 3px 6px 0 #d8e7fd"
      >
        <general-contact
          :content="content"
          style="border-radius: 4px"
          tracking-event="Homepage"
        />
      </q-card>
    </q-padder>
    <!-- Regulatory Section -->
    <regulatory-section v-if="countryCode === 'MY'" :content="content" />
    <!-- Acceptable Payment -->
    <q-column v-if="isShowContent" center wrap>
      <q-padder vertical="xs" style="text-align: center">
        <q-spacer bottom="sm">
          <q-text variant="ui-small" ink="label" bold center>{{
            content.QoalaOnlineHomepage.weAcceptLabel
          }}</q-text>
        </q-spacer>
        <img
          :src="PaymentLogo"
          alt="Payment Logo"
          title="Payment Logo"
          style="width: 65%"
        />
      </q-padder>
    </q-column>
    <!-- Social Media Section -->
    <q-padder v-if="isShowContent" top="sm" bottom="md">
      <q-spacer bottom="xs">
        <q-text variant="ui-small" ink="label" bold center>{{
          content.QoalaOnlineGeneral.footerHeadSocialmedia
        }}</q-text>
      </q-spacer>
      <q-row center justify="center">
        <q-spacer
          v-for="(socmed, index) in socialMedia"
          :key="index"
          :title="socmed.name"
          horizontal="xs"
          @click.native="goToSocialMedia(socmed.url)"
        >
          <img
            :src="socmed.icon"
            :alt="socmed.name"
            width="20"
            height="20"
            :title="socmed.name"
            style="cursor: pointer"
          />
        </q-spacer>
      </q-row>
    </q-padder>
    <!-- Footer -->
    <footer-section
      v-if="isShowContent"
      :content="content.QoalaOnlineGeneral"
    />
    <!-- Floating CS Button -->
    <q-text v-if="isShowWhatsappIcon" class="contact-us" title="Whatsapp Chat">
      <img
        id="btn-waChat"
        src="https://img.qoala.app/qapp-assets/images/homepage/new-cs-icon.png"
        alt="Chat Icon"
        width="56"
        height="78"
        title="Customer Service"
        @click="goToWhatsApp"
        @keypress="goToWhatsApp"
      />
    </q-text>
  </general-template>
</template>

<script>
import {
  Padder as QPadder,
  Text as QText,
  Card as QCard,
  Column as QColumn,
  Row as QRow,
  Spacer as QSpacer,
} from '@qoala/ui';
import { Button as QButton } from '@qoala/ui/v2';
import GeneralTemplate from '@/components/general/GeneralTemplate';
import ToolSlider from '@/components/tool/ToolSlider.vue';
import ToolProgressBar from '@/components/tool/ToolProgressBar';
import GeneralContact from '@/components/general/GeneralContact';
const AddBannerEng =
  'https://img.qoala.app/qapp-assets/images/banner/banner-claim-en.png?tr=w-464';
const AddBannerInd =
  'https://img.qoala.app/qapp-assets/images/banner/banner-claim-id.png?tr=w-464';
const PaymentLogo =
  'https://img.qoala.app/qapp-assets/images/homepage/payment-logo.png?tr=w-234,h-45';
const QoalaCareBannerIndonesiaEN =
  'https://img.qoala.app/qapp-assets/images/banner/Qoala_Care_Banner_Indonesia-EN.png';
const QoalaCareBannerIndonesiaID =
  'https://img.qoala.app/qapp-assets/images/banner/Qoala_Care_Banner_Indonesia-ID.png';
const SmartphoneVnBanner = `${process.env.ASSETS_URL}/images/banner/banner-vietnam-smartphone-vn-en.jpg`
const SmartphoneVnEnBanner = `${process.env.ASSETS_URL}/images/banner/banner-vietnam-smartphone-vn.jpg`
//SECTION
import productSection from './parts/productSection';
import listCountry from '@/static/data/countryList.json';
// SEO
import {
  CONST_LOCALE_MAP,
  ROBOTS_INDEX_FOLLOW,
  CONST_OFFICE_ADDRESS,
  CUST_SVC_WHATSAPP_MALAY,
} from '@/shared/constants';
import {
  webpageSchema,
  organizationSchema,
  siteNavigationElementSchema,
  insuranceAgencySchema,
} from '@/utils/common/generateMetadata';
import metaList from '@/src/purchase/parts/metaList';
import sanitizePhoneCountryCode from '~/utils/common/sanitizePhoneCountryCode';
import localeCodeToLanguage from '~/utils/common/localeCodeToLanguage';

export default {
  components: {
    QPadder,
    QCard,
    QText,
    QButton,
    QColumn,
    QRow,
    ToolSlider,
    GeneralTemplate,
    GeneralContact,
    ToolProgressBar,
    QSpacer,
    productSection,
    uspSection: () => import('./parts/uspSection'),
    softSellSection: () => import('./parts/softSellSection'),
    articleSection: () => import('./parts/articleSection'),
    partnerSection: () => import('./parts/partnerSection'),
    vehicleProtectionSection: () => import('./parts/vehicleProtectionSection'),
    FaqSection: () => import('./parts/faqSection'),
    regulatorySection: () => import('./parts/regulatorySection'),
    footerSection: () => import('./parts/footerSection'),
    AddToHomeScreen: () => import('@/components/tool/AddToHomescreen.vue'),
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
    countryCode: {
      type: String,
      default: '',
    },
    bannerList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoggedIn: false,
      checkNotif: false,
      homeNotif: false,
      installPromptEvent: null,
      statusVerified: '',
      tempNotVerified: [],
      statusEmergencyContact: '',
      locationDenied: 'denied',
      isBannerLoading: true,
      qoalaCareBanner: [
        {
          value:
            this.$i18n.locale === 'id'
              ? QoalaCareBannerIndonesiaID
              : QoalaCareBannerIndonesiaEN,
          item: {
            url:
              this.$i18n.locale === 'id'
                ? 'https://qoalacare.qoala.app/'
                : 'https://qoalacare.qoala.app/en',
            static: true,
            query: null,
            name: 'Qoala Care',
          },
        },
      ],
      staticIdBanner: [
        {
          value: this.$i18n.locale === 'id' ? AddBannerInd : AddBannerEng,
          item: {
            url: 'policy-add',
            static: true,
            query: null,
            name: 'Policy',
          },
        },
      ],
      PaymentLogo,
      socialMedia: [
        {
          name: 'Facebook',
          icon:
            'https://img.qoala.app/qapp-assets/images/general/logo-facebook.png',
          url: 'https://www.facebook.com/qoalaid',
        },
        {
          name: 'Twitter',
          icon:
            'https://img.qoala.app/qapp-assets/images/general/logo-twitter.png',
          url: 'https://twitter.com/qoalaid',
        },
        {
          name: 'Tiktok',
          icon:
            'https://img.qoala.app/qapp-assets/images/general/logo-tiktok.svg',
          url: 'https://www.tiktok.com/@qoalaid',
        },
        {
          name: 'Instagram',
          icon:
            'https://img.qoala.app/qapp-assets/images/general/logo-instagram.png',
          url: 'https://www.instagram.com/qoalaid/',
        },
        {
          name: 'LinkedIn',
          icon:
            'https://img.qoala.app/qapp-assets/images/general/logo-linkedin.png',
          url: 'https://www.linkedin.com/company/qoala/',
        },
        {
          name: 'Youtube',
          icon:
            'https://img.qoala.app/qapp-assets/images/general/logo-youtube.png',
          url: 'https://www.youtube.com/channel/UCTAK0Lsbi2NZHLpCrq7SHXg/',
        },
      ],
    };
  },
  head() {
    const country = CONST_LOCALE_MAP[this.countryCode] || 'id';
    let entryHomepage = 'homepage';
    let entryAccount = 'account';
    switch(this.countryCode) {
      case 'MY':
        entryHomepage = 'homepageMy';
        entryAccount = 'accountMy';
        break;
      case 'VN':
        entryHomepage = 'homepageVn';
        entryAccount = 'accountVn';
        break;
      default:
        break;
    }
    const meta = {
      title: this.content.MetaTitle[entryHomepage],
      description: this.content.MetaDescription[entryHomepage],
    };
    const postPurchase = 'policy-add';
    const contentHomepage = this.content.QoalaOnlineHomepage;
    const productList = [
      {
        name: contentHomepage.policyLabel,
        description: '',
        url: this.localePath('products'),
      },
      {
        name: contentHomepage.claimLabel,
        description: '',
        url: this.isLoggedIn
          ? this.localePath('products')
          : this.localePath(postPurchase),
      },
      {
        name: contentHomepage.notificationLabel,
        description: '',
        url: this.localePath('notification'),
      },
      {
        name: contentHomepage.accountLabel,
        description: this.content.MetaDescription[entryAccount],
        url: this.localePath('account'),
      },
    ];
    const isUseOfficeAddress = ['id', 'my'].includes(country);
    return {
      title: meta.title,
      meta: [ROBOTS_INDEX_FOLLOW, ...metaList(meta.title, meta.description)],
      script: [
        webpageSchema({
          country,
          name: meta.title,
          description: meta.description,
        }),
        organizationSchema({
          country,
        }),
        siteNavigationElementSchema({
          name: 'Main Navigation',
          productList,
        }),
        // TODO: Using Content (Airtable) for Malaysia and Indonesia
        insuranceAgencySchema(
          {
            country,
            name: `Qoala ${
              this.countryCode === 'MY' ? 'Malayasia' : 'Indonesia'
            }`,
            description: meta.description,
            telephone: '+62 811-1171-708',
          },
          isUseOfficeAddress && this.getOfficeAddress(contentHomepage)
        ),
      ],
      link: [
        {
          rel: 'canonical',
          href: `https://www.qoala.app/${this.countryCode.toLowerCase()}`,
        },
      ],
    };
  },
  computed: {
    isShowContent() {
      if (this.countryCode !== 'ID') return false;
      return true;
    },
    isShowWhatsappIcon() {
      return this.countryCode == 'ID' || this.countryCode == 'MY';
    },
    boxShadow() {
      return `border:none;box-shadow: 0 3px 6px 0 #d8e7fd;`;
    },
    carousel() {
      let carouselList = [];
      if (this.countryCode === 'ID') {
        this.bannerList.map((item) => {
          carouselList.push({
            value: this.$i18n.locale === 'id' ? item.locale : item.en,
            item: {
              url: item.url,
              static: true,
              query: item.query ? item.query : null,
              name: item.name,
            },
          });
        });
        carouselList = [
          ...this.qoalaCareBanner,
          ...carouselList,
          ...this.staticIdBanner,
        ];
      } else if (this.countryCode === 'MY') {
        this.bannerList.map((item) => {
          carouselList.push({
            value: this.$i18n.locale === 'my' ? item.locale : item.en,
            item: {
              url: item.url,
              static: true,
              query: item.query ? item.query : null,
              name: item.name,
            },
          });
        });
      } else if (this.countryCode === 'VN') {
        carouselList.unshift({
          value: this.$i18n.locale === 'vn' ? SmartphoneVnBanner : SmartphoneVnEnBanner ,
          item: {
            url: 'policy-add',
            static: true,
            query: null,
            name: 'Policy',
          },
        });
      } else {
        carouselList.push({
          value: this.$i18n.locale === 'id' ? AddBannerInd : AddBannerEng,
          item: {
            url: 'policy-add',
            static: true,
            query: null,
            name: 'Policy',
          },
        });
      }
      return carouselList;
    },
    getUserData() {
      return this.$store.getters['user/getUserData'];
    },
    progressValue() {
      const verify = 'VERIFIED';
      const {
        isIdentityVerified,
        isBankVerified,
        isPhoneVerified,
        isEmailVerified,
      } = this.getUserData;
      const arrInfoVerified = [
        {
          title: 'ktp',
          status: isIdentityVerified,
          label: this.content.QoalaOnlineHomepage.updateIdLabel,
          point: 25,
        },
        {
          title: 'bank',
          status: isBankVerified,
          label: this.content.QoalaOnlineHomepage.updateIdLabel,
          point: 25,
        },
        {
          title: 'phone',
          status: isPhoneVerified,
          label: this.content.QoalaOnlineHomepage.verifyPhoneLabel,
          point: 20,
        },
        {
          title: 'email',
          status: isEmailVerified,
          label: this.content.QoalaOnlineHomepage.verifyEmailLabel,
          point: 20,
        },
        {
          title: 'emergency',
          status: this.statusEmergencyContact,
          label: this.content.QoalaOnlineHomepage.updateEmergencyLabel,
          point: 10,
        },
      ];
      let tempValue = 0;
      for (let i = 0; i < arrInfoVerified.length; i++) {
        if (arrInfoVerified[i].status !== verify) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.tempNotVerified.push(arrInfoVerified[i]);
        }
        if (arrInfoVerified[i].status === verify) {
          tempValue += arrInfoVerified[i].point;
        }
        if (arrInfoVerified[i].status === false) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.tempNotVerified.push(arrInfoVerified[i]);
        }
        if (arrInfoVerified[i].status === true) {
          tempValue += arrInfoVerified[i].point;
        }
      }
      if (!this.tempNotVerified[0]) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.statusVerified = '';
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.statusVerified = this.tempNotVerified[0].label;
      }
      return tempValue;
    },
  },
  created() {
    this.checkHomescreen();

    this.getInsurancePartner();
    this.removeHistory();
  },
  async mounted() {
    const userCountry = listCountry.find(
      (item) => item.countryCode === this.countryCode
    );
    let analyticData = {
      Language: localeCodeToLanguage(this.$i18n.locale),
      Country: userCountry?.name,
    };
    this.appPermission();
    let token = this.$store.getters['user/getUserToken'];
    if (token) {
      await this.$store.dispatch('user/getProfile', {
        callback: () => this.checkedVerifyEmegency(this.getUserData),
      });
      this.isLoggedIn = true;
      analyticData['User name'] = this.getUserData.fullName;
      analyticData['User email'] = this.getUserData.email;
      analyticData['User Phone Number'] = sanitizePhoneCountryCode(
        this.getUserData?.account?.phoneNumber
      );
    }
    this.$analytics.pushEventClevertap('Homepage', analyticData);

    //Accestrade for MY for integration to Affiliate Marketing System
    if (
      this.countryCode === 'MY' &&
      typeof LP_UNIX_TIME_0_IN_GMT === 'undefined'
    ) {
      const timestamp = new Date().getTime();
      (function (d) {
        let s = d.createElement('script');
        s.src = 'https://script.accesstrade.global/lp.js?cb=' + timestamp;
        s.async = true;
        let e = d.getElementsByTagName('script')[0];
        e.parentNode.insertBefore(s, e);
      })(document);
    }
  },
  methods: {
    getOfficeAddress(contentHomepage) {
      const addressByCountry = CONST_OFFICE_ADDRESS[this.countryCode];
      return {
        addressLocality: contentHomepage[addressByCountry.addressLocality],
        addressRegion: contentHomepage[addressByCountry.addressRegion],
        postalCode: contentHomepage[addressByCountry.postalCode],
        streetAddress: contentHomepage[addressByCountry.streetAddress],
      };
    },
    handleSearchProduct() {
      this.$router.push(this.localePath('purchase-car-my-product'));
    },
    removeHistory() {
      this.$store.commit('vehicle/deleteCarPurchaseHistory');
      this.$store.commit('vehicle/deleteData');
      this.$store.commit('health/deleteHealthData', {});
      this.$store.commit('tropical-disease/deleteData');
      this.$store.commit('hcp/deleteHcpData');
      this.$store.commit('smartphone/deleteData');
      this.$store.commit('lifeInsurance/clearData');
      this.$store.commit('quotation/removeQuotationSummary');
      this.$store.commit('general/removeComparedProduct');
      this.$store.commit('promo/updatePromoCalculation', {});
      this.$store.commit('policy/deleteDataPolicyCreation');
      this.$store.commit('policy/updatePurchaseConfirmation', {});
      this.$store.commit('general/removeSnapToken');
      this.$store.commit('property/deletePropertyData');
      this.$store.commit('personal-accident/deletePersonalAccidentData');
      this.$store.commit('promo/deleteListPayment');
      this.$store.commit('user/removeTempSign');
      this.$store.commit('quotation/removeQuotationScraping');
      this.$store.commit('health-my/clearAllData');
    },
    appPermission() {
      const userData = this.$store.getters['user/getUserData'];
      if (process.client) {
        if (navigator.permissions) {
          navigator.permissions
            .query({ name: 'geolocation' })
            .then((result) => {
              this.locationDenied = result.state;
              result.onchange = () => {
                this.locationDenied = result.state;
              };
            });
        } else {
          this.locationDenied = 'granted';
        }
        if (userData) {
          const dataTags = {
            Identity: userData.uuid,
            email: userData.email,
          };
          if (
            window.webkit &&
            window.webkit.messageHandlers &&
            window.webkit.messageHandlers.qoala
          ) {
            window.webkit.messageHandlers.qoala.postMessage({
              action: 'sendTags',
              content: dataTags,
            });
          }
        }
      }
    },
    checkedVerifyEmegency(data) {
      if (data) {
        if (
          !data.beneficiaryFullName &&
          !data.beneficiaryPhoneNumber &&
          !data.beneficiaryRelationship
        ) {
          this.statusEmergencyContact = 'NOT VERIFIED';
        } else if (
          data.beneficiaryFullName.length > 0 &&
          data.beneficiaryPhoneNumber.length > 0 &&
          data.beneficiaryRelationship.length > 0
        ) {
          this.statusEmergencyContact = 'VERIFIED';
        } else {
          this.statusEmergencyContact = 'NOT VERIFIED';
        }
        return 'NOT VERIFIED';
      }
      return '';
    },
    async getInsurancePartner() {
      if (this.countryCode === 'MY') {
        const partnersMy = [
          {
            alias: 'ZURICH',
            name: 'Zurich',
            logo: 'https://assets.qoalaplus.com/images/zurich.png',
          },
          {
            alias: 'TUNEPROTECT',
            name: 'Tune Protect',
            logo: 'https://assets.qoalaplus.com/images/tune-protect.png',
          },
          {
            alias: 'ETIQA',
            name: 'Etiqa',
            logo: 'https://assets.qoalaplus.com/images/etiqa.png',
          },
          {
            alias: 'TAKAFUL',
            name: 'Takaful Ikhlas',
            logo: 'https://assets.qoalaplus.com/images/takaful-ikhlas.png',
          },
          {
            alias: 'AXA-AFFIN',
            name: 'Axa Affin',
            logo: 'https://assets.qoalaplus.com/images/axa-affin.png',
          },
        ];
        return this.$store.commit('general/updateInsurancePartner', partnersMy);
      }
      await this.$store.dispatch('general/getInsurancePartner');
    },
    removeNotifHome(dataNotif) {
      this.homeNotif[dataNotif] = false;
      this.$forceUpdate();
      this.$store.commit('general/updateNotifHome', this.homeNotif);
    },
    checkHomescreen() {
      if (!process.client) {
        return null;
      }
      const pointer = this;
      const ua = navigator.userAgent.toLowerCase();
      const isIPhone = /iphone/.test(ua);
      const isIPad = /ipad/.test(ua);
      const isIos = isIPhone || isIPad;
      if (!window.matchMedia('(display-mode: standalone)').matches) {
        if (
          window.matchMedia &&
          !window.matchMedia('(display-mode: standalone)').matches
        ) {
          if (isIos) {
            this.homeNotif = true;
            if (window.matchMedia('(display-mode: standalone)').matches)
              this.homeNotif = false;
          }
        } else {
          this.homeNotif = false;
        }
        this.installPromptEvent = this.$store.getters['general/getA2hs'];
        window.addEventListener('beforeinstallprompt', (e) => {
          e.preventDefault();
          pointer.installPromptEvent = e;
          pointer.homeNotif = true;
          this.$store.commit('general/updateA2hs', e);
          pointer.$forceUpdate();
        });
        if (this.installPromptEvent) {
          pointer.homeNotif = true;
        } else {
          pointer.homeNotif = false;
        }
      } else {
        this.homeNotif = false;
      }
    },
    goToWhatsApp() {
      const now = this.$moment().format('YYYY-MM-DDTHH:mm');
      const startDate = this.$moment().format('2020-12-31T19:00');
      const endDate = this.$moment().format('2021-01-01T07:00');
      let analyticData = {};
      if (this.$moment(now).isBetween(startDate, endDate)) {
        const message = {
          title: 'Halo,',
          message: `Kami mohon maaf untuk malam ini tanggal 31/12/2020 jam 19.00, Qoala tidak beroperasi dikarenakan alasan operasional. Jika kamu memiliki pertanyaan, silahkan menghubungi kami kembali besok tanggal 01/01/2021 pada jam 07.00. Kami akan dengan senang hati membantu menjawab pertanyaanmu.
          \n Selamat Tahun Baru 2021!"`,
        };
        this.$store.dispatch('general/setModalMessage', true);
        this.$store.dispatch('general/setMessage', message);
        return false;
      }
      this.$analytics.pushEventClevertap('footer', analyticData);
      this.$analytics.pushEvent({
        event: 'Homepage',
        eventCategory: 'Homepage',
        eventAction: 'CS-Help',
        eventLabel: 'Homepage-Click-Floating-Chat',
      });
      this.$analytics.pushEventClevertap('Ask Us', {});
      const csQoala =
        this.countryCode !== 'ID' ? CUST_SVC_WHATSAPP_MALAY : '6282210333220';
      this.$helper.openNewTab(
        `https://api.whatsapp.com/send?phone=${csQoala}`,
        '_blank'
      );
    },
    goToPartnerPromoPage() {
      this.$analytics.pushEvent({
        event: 'Homepage',
        eventCategory: 'Homepage',
        eventAction: 'Banner',
        eventLabel: 'Homepage-Click-BN-PartnerPromotion',
      });
      typeof window !== 'undefined' &&
        this.$helper.openNewTab(
          'https://www.qoala.app/id/blog/partner/',
          '_blank'
        );
    },
    goToSocialMedia(url) {
      this.$helper.openNewTab(url, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~/assets/css/var.scss';

.eb {
  &-section {
    position: relative;
    cursor: pointer;
    padding: 0 8px;
  }
  &-wrapper {
    width: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 16px;
    right: 0;
    img {
      border-radius: 4px;
      overflow: hidden;
    }
    .eu-btn {
      border-radius: 8px;
      background: $gradient-primary-orange;
      border: none;
      font-weight: bold;
      @media screen and (max-width: 375px) {
        font-size: 10px;
      }
    }
    @media screen and (max-width: 414px) {
      .ui-large {
        font-size: 14px;
      }
    }
    @media screen and (max-width: 375px) {
      .ui-large {
        font-size: 13px;
      }
    }
    @media screen and (max-width: 320px) {
      .ui-large {
        font-size: 11px;
      }
    }
  }
}

@media (min-width: 480px) {
  .contact-us {
    left: calc(50vw + 240px - 56px);
  }
}

.contact-us {
  position: fixed;
  bottom: 115px;
  right: 0;
  margin: auto;
  margin-right: 0;
  img {
    width: 56px;
    margin-right: 8px;
    cursor: pointer;
  }
}
</style>
